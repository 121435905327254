//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    CContent: () => import("@/components/CContent"),
    Sethourlywage: () => import("./set-hourlywage-modal"),
    CPages: () => import("@/components/CPages"),
  },
  props: {
    selUser: {
      type: Object,
      default: null,
    },
    teamValue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
      },
      loading: false,
      cellStyle: {
        textAlign: "center",
      },
      indexData: {
        type: "", // Add Edit
        name: "",
        departmentCode: "",
      },
      tableData: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    /**
     * 分页
     */
    handlePaginationChange(val) {
      this.pageData = val;
      this.getDataList();
    },

    // 删除某一行
    handleDelt(row) {
      this.$confirm("此操作将删除此时薪, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            Ids: [row.Id],
            teamId: this.teamValue,
          };
          this.comDelete(params);
        })
        .catch(() => {});
    },
    comDelete(params) {
      this.$http
        .post("/Teams/MemberWage/DelMemberWageType.ashx", params)
        .then((result) => {
          if (result.res == 0) {
            this.$message({
              showClose: true,
              message: "删除成功！",
              type: "success",
            });
            this.getDataList();
          }
        });
    },

    // 新增
    addClick() {
      this.openWin("ad");
    },
    // 编辑
    handleEdit(row) {
      this.openWin("ed", row.Id, row);
    },
    // 打开窗口
    openWin(ty, code, row) {
      this.indexData = {
        type: ty === "ad" ? "Add" : "Edit",
        name: ty === "ad" ? "添加时薪" : "编辑时薪",
        departmentCode: code,
        row: row,
        xModalName: "hourlywageSetM",
      };
      this.$modal.show("hourlywageSetM");
      this.$nextTick(() => {
        this.$refs.hourlywageSetM.changeEditState();
      });
    },
    // 获取数据
    getDataList() {
      let params = {
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        teamId: this.teamValue,
      };
      this.loading = true;
      this.$http
        .post("/Teams/MemberWage/GetMemberWageType.ashx", params)
        .then((result) => {
          if (result.res == 0) {
            this.tableData = result.data.Data;
            // this.pageData.totalNum = result.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
